// src/components/graph/KnowledgeGraph.jsx

import React, { useEffect, useRef } from 'react';
import CytoscapeComponent from 'react-cytoscapejs';
import Cytoscape from 'cytoscape';
import fcose from 'cytoscape-fcose';

Cytoscape.use(fcose);

const KnowledgeGraph = ({ data, height = 600 }) => {
  const cyRef = useRef(null);

  const layout = {
    name: 'fcose',
    animate: false,
    randomize: true,
    nodeDimensionsIncludeLabels: true,
    packComponents: true,
    nodeSeparation: 200,
    idealEdgeLength: 100,
    edgeElasticity: 0.45,
    gravity: 0.25,
  };

  const buildElements = () => {
    const clusterElements = data.clusters.map((cluster) => ({
      data: {
        id: cluster.id,
        label: cluster.label,
        color: cluster.color,
        shape: cluster.shape,
      },
      classes: 'cluster',
      group: 'nodes',
    }));

    const nodeElements = data.nodes.map((node) => {
      const nodeData = {
        id: node.id,
        label: node.label,
        color: node.color,
        shape: node.shape,
      };
      if (node.parent) {
        nodeData.parent = node.parent;
      }
      return {
        data: nodeData,
        group: 'nodes',
      };
    });

    const edgeElements = data.links.map((link) => ({
      data: {
        source: link.source,
        target: link.target,
        color: link.color,
        width: link.width,
        style: link.style,
        arrowShape: link.arrowShape,
        label: link.label,
      },
      group: 'edges',
    }));

    return [...clusterElements, ...nodeElements, ...edgeElements];
  };

  const stylesheet = [
    {
      selector: 'node',
      style: {
        'background-color': 'data(color)',
        shape: 'data(shape)',
        label: 'data(label)',
        'text-wrap': 'wrap',
        'text-max-width': 100,
        'font-size': '14px',
        'text-valign': 'center',
        'text-halign': 'center',
        width: 'label',
        height: 'label',
        padding: '10px',
      },
    },
    {
      selector: 'node:parent',
      style: {
        'background-color': 'data(color)',
        shape: 'data(shape)',
        'border-color': '#a0a0a0',
        'border-width': 2,
        label: 'data(label)',
        'text-valign': 'top',
        'text-halign': 'center',
        'text-wrap': 'wrap',
        'text-max-width': 150,
        'font-size': '16px',
        padding: '20px',
      },
    },
    {
      selector: 'edge',
      style: {
        'line-color': 'data(color)',
        width: 'data(width)',
        'line-style': 'data(style)',
        'source-arrow-shape': 'data(arrowShape)',
        'source-arrow-color': 'data(color)',
        'curve-style': 'bezier',
        'label': 'data(label)',
        'font-size': '12px',
        'color': '#555',
        'text-background-color': '#fff',
        'text-background-opacity': 0.7,
        'text-rotation': 'autorotate',
        'text-margin-y': '-10px',
        'edge-text-rotation': 'autorotate',
      },
    },
    {
      selector: '.cluster',
      style: {
        'background-color': 'data(color)',
        'shape': 'data(shape)',
        'label': 'data(label)',
        'text-valign': 'top',
        'text-halign': 'center',
        'font-size': '16px',
        'border-width': 2,
        'border-color': '#a0a0a0',
      },
    },
  ];

  useEffect(() => {
    if (cyRef.current) {
      const cy = cyRef.current;
      cy.elements().remove();
      const elements = buildElements();
      cy.add(elements);
      cy.layout(layout).run();
    }
  }, [data]);

  return (
    <div style={{ width: '100%', height: `${height}px` }}>
      <CytoscapeComponent
        cy={(cy) => {
          cyRef.current = cy;
        }}
        elements={[]}
        stylesheet={stylesheet}
        style={{ width: '100%', height: '100%' }}
      />
    </div>
  );
};

export default KnowledgeGraph;