// src/graphData.js

export const createGraphData = (dataset, options = {}) => {
  console.log('Dataset:', dataset);

  const nodesInput = dataset.nodes || [];
  const edgesInput = dataset.edges || [];
  const clustersInput = dataset.clusters || [];
  const { defaultNodeColor = 'gray', defaultEdgeColor = 'black' } = options;

  const clusters = [];
  const nodeIdMap = {}; // Map to store node IDs and their data

  const mapShape = (shape) => {
    const shapeMap = {
      'ellipse': 'ellipse',
      'circle': 'octagon',
      'polygon': 'hexagon',
      'egg': 'hexagon',
      'box': 'rectangle',
      'roundrectangle': 'roundrectangle'
    };
    return shapeMap[shape] || 'ellipse';
  };

  // Process nodes without assigning parents
  nodesInput.forEach((node) => {
    const nodeId = node.id.toString().trim(); // Ensure ID is string
    const nodeData = {
      id: nodeId,
      label: node.label,
      color: node.color || defaultNodeColor,
      shape: mapShape(node.shape) || 'ellipse',
    };
    nodeIdMap[nodeId] = nodeData;
  });

  // Process clusters and assign parents
  clustersInput.forEach((cluster) => {
    const clusterId = cluster.id;
    const clusterNodes = cluster.nodes.map((id) => id.toString()); // Ensure IDs are strings

    clusters.push({
      id: clusterId,
      label: cluster.label,
      color: cluster.color || defaultNodeColor,
      shape: cluster.shape || 'roundrectangle',
      nodes: clusterNodes,
    });

    // Assign parent to nodes in this cluster
    clusterNodes.forEach((nodeId) => {
      if (nodeIdMap[nodeId]) {
        nodeIdMap[nodeId].parent = clusterId;
      } else {
        console.error(
          `Node with ID ${nodeId} not found in nodeIdMap when assigning to cluster '${cluster.label}'.`
        );
      }
    });
  });

  // Convert nodeIdMap to nodes array
  const nodes = Object.values(nodeIdMap);

  // Process edges
  console.log('Edges input:', edgesInput);
  const links = [];

  edgesInput.forEach((edge) => {
    const { source, target, color, width, style, arrowShape, label} = edge;

    // Determine if the target includes a label


    links.push({
      source: source.toString(),
      target: target,
      color: color || defaultEdgeColor,
      width: width || 2,
      style: style || 'solid',
      arrowShape: arrowShape === 'diamond' ? 'diamond' : 'triangle',
      label: label, // Assign the label
    });
  });

  // Return the processed graph data
  return { nodes, links, clusters };
};
