import React from 'react';
import './index.css'; // Import Tailwind CSS
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from './firebase'; 
import { BrowserRouter as Router, Routes, Route, Link, Navigate, useLocation } from 'react-router-dom';
import BuilderPage from './pages/BuilderPage';

// Pages
import Home from './pages/Home'; 
import About from './pages/About';
import LoginPage from './pages/LoginPage';
import ManageAccount from './pages/ManageAccount';

const ProtectedRoute = ({ user, children }) => {
  return user ? children : <Navigate to="/login" replace />;
};

const AppContent = () => {
  const [user, loading] = useAuthState(auth);
  const location = useLocation();

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        Loading...
      </div>
    );
  }

  // Utility classes for link styling
  const linkBaseClasses = "px-4 py-2 rounded-md text-sm font-medium transition";
  const activeClasses = "bg-[#E5E5E5] text-gray-700";
  const defaultClasses = "text-gray-700 hover:bg-gray-200";

  // Determine active routes:
  const aboutActive = location.pathname === "/about";
  const loginActive = location.pathname === "/login";
  const manageAccountActive = location.pathname === "/manage-account";

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
      <header className="bg-white shadow shrink-0">
        <div className="w-full px-6 py-4 flex justify-between items-center">
        <div className="flex items-center">
        <Link to="/">
          <img 
            src="/logo.png" 
            alt="Logo" 
            className="h-auto w-auto mr-3" 
            style={{ maxHeight: '40px' }} 
          />
        </Link>
      </div>
          <nav className="flex space-x-4 items-center">
  {user ? (
    <>
      <Link 
        to="/builder" 
        className={`${linkBaseClasses} ${location.pathname === "/builder" ? activeClasses : defaultClasses}`}
      >
        Knowledge Graph Builder
      </Link>
      {/* Place the About link after Visualizer Test */}
      <Link 
        to="/about" 
        className={`${linkBaseClasses} ${aboutActive ? activeClasses : defaultClasses}`}
      >
        About
      </Link>
      <Link 
        to="/manage-account" 
        className={`${linkBaseClasses} ${manageAccountActive ? activeClasses : defaultClasses}`}
      >
        Manage Account
      </Link>
      <button
        onClick={() => auth.signOut()}
        className={`${linkBaseClasses} ${defaultClasses}`}
      >
        Logout
      </button>
    </>
  ) : (
    <>
      <Link
        to="/login"
        className={`${linkBaseClasses} ${loginActive ? activeClasses : defaultClasses}`}
      >
        Login
      </Link>
      {/* Include the About link for unauthenticated users as well */}
      <Link 
        to="/about" 
        className={`${linkBaseClasses} ${aboutActive ? activeClasses : defaultClasses}`}
      >
        About
      </Link>
    </>
  )}
</nav>
        </div>
      </header>

      {/* Removing max-width, padding and margin restrictions from the main content area */}
      <main className="flex-grow">
        <Routes>
          {/* Public routes */}
          <Route path="/about" element={<About />} />
          <Route path="/login" element={user ? <Navigate to="/" replace /> : <LoginPage />} />
          
          {/* If no user, "/" is Home. If user, "/" is Visualizer */}
          <Route 
            path="/" 
            element={user ? <BuilderPage /> : <Home />} 
          />

          {/* Protected routes */}

          <Route 
            path="/manage-account" 
            element={
              <ProtectedRoute user={user}>
                <ManageAccount />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/builder" 
            element={
              <ProtectedRoute user={user}>
                <BuilderPage />
              </ProtectedRoute>
            } 
          />

          {/* Catch-all route */}
          <Route 
            path="*" 
            element={<Navigate to="/" replace />} 
          />
        </Routes>
      </main>

      <footer className="bg-white shadow shrink-0">
        <div className="w-full px-6 py-4 text-center text-gray-500 text-sm">
          &copy; {new Date().getFullYear()} Atlaz. All rights reserved.
        </div>
      </footer>

      <ToastContainer position="top-right" autoClose={3000} hideProgressBar closeOnClick />
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <AppContent />
    </Router>
  );
};

export default App;