import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import CurveView from '../components/graph/CurveView';
import Button from '../components/common/Button';
import { auth } from '../firebase';
import { cachedExamples } from '../cached_examples';

const BuilderPage = () => {
  const [inputText, setInputText] = useState('');
  const [customizationText, setCustomizationText] = useState(''); 
  const [graphData, setGraphData] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [status, setStatus] = useState('');

  // States to hold the contents of each example file
  const [exampleData1, setExampleData1] = useState('');
  const [exampleData2, setExampleData2] = useState('');
  const [exampleData3, setExampleData3] = useState('');
  const [exampleData4, setExampleData4] = useState('');
  // Fetch the example files when component mounts
  useEffect(() => {
    fetch('/example1.txt')
      .then(response => response.text())
      .then(data => setExampleData1(data))
      .catch(err => console.error('Error loading example1:', err));

    fetch('/example2.txt')
      .then(response => response.text())
      .then(data => setExampleData2(data))
      .catch(err => console.error('Error loading example2:', err));

    fetch('/example3.txt')
      .then(response => response.text())
      .then(data => setExampleData3(data))
      .catch(err => console.error('Error loading example3:', err));

      fetch('/example4.txt')
      .then(response => response.text())
      .then(data => setExampleData4(data))
      .catch(err => console.error('Error loading example3:', err));
  }, []);

  const handleExampleClick = (exampleText) => {
    setInputText(exampleText);
  };

  const handleSubmit = async () => {
    if (!inputText.trim()) {
      toast.error('Please enter some source data');
      return;
    }
    if (inputText.trim() === exampleData2.trim()) {
      setGraphData(cachedExamples.example2);
      setStatus("Loaded from cache");
      return;
    }
    if (inputText.trim() === exampleData1.trim()) {
      setGraphData(cachedExamples.example1);
      setStatus("Loaded from cache");
      return;
    }
    if (inputText.trim() === exampleData3.trim()) {
      setGraphData(cachedExamples.example3);
      setStatus("Loaded from cache");
      return;
    }
    if (inputText.trim() === exampleData4.trim()) {
      setGraphData(cachedExamples.example4);
      setStatus("Loaded from cache");
      return;
    }
    setIsProcessing(true);
    setStatus('Processing...');
    setGraphData(null);
    try {
      const token = await auth.currentUser.getIdToken(true);
      console.log('Token:', token);
      const response = await fetch('https://atlaz-api.com/generate_graph', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
        body: JSON.stringify({ 
          text: inputText, 
          customization: customizationText
        })
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let buffer = '';

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;

        buffer += decoder.decode(value, { stream: true });
        const lines = buffer.split('\n');
        buffer = lines.pop();

        for (const line of lines) {
          if (line.trim()) {
            try {
              const chunk = JSON.parse(line);
              console.log("Received chunk:", chunk);

              if (chunk.status === "still_processing") {
                setStatus("Still processing...");
              } else if (chunk.status === "completed" && chunk.graph) {
                const { nodes, links, clusters } = chunk.graph;
                const formattedData = {
                  nodes: nodes || [],
                  edges: links || [], 
                  clusters: clusters || []
                };
                console.log("Formatted graph data:", formattedData);
                setGraphData(formattedData);
                setStatus("Completed");
              }
            } catch (parseError) {
              console.error("Error parsing JSON:", parseError, "Line:", line);
            }
          }
        }
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to generate graph');
      setStatus('Error occurred');
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="min-h-screen p-6">
      <div className="mb-6">
        <h1 className="text-2xl font-bold mb-4">Build your own knowledge graph</h1>
        
        <p className="mb-4 text-gray-700 leading-relaxed">
          Enter your source data and optional customization instructions below. I recommend starting without customization. Then click "Generate Graph" to create a Knowledge Graph. 
          Be patient—this can take up to 10 minutes due to current rate limits. Please report any bugs to rasmus.bjersing@gmail.com.
          It should be able to process up to 20-40 pages of text thus far (15 pages for dense math). 
        </p>

        {/* Example data section */}
        <div className="mb-4">
          <h2 className="text-lg font-semibold mb-2">Click on an Example</h2>
          <div className="flex space-x-4">
            <div 
              className="border border-gray-300 p-4 cursor-pointer hover:bg-gray-100 rounded"
              onClick={() => handleExampleClick(exampleData1)}
            >
              <strong>EU AI Act</strong> 
            </div>
            <div 
              className="border border-gray-300 p-4 cursor-pointer hover:bg-gray-100 rounded"
              onClick={() => handleExampleClick(exampleData2)}
            >
              <strong>Introductory Abstract Algebra</strong> 
            </div>
            <div 
              className="border border-gray-300 p-4 cursor-pointer hover:bg-gray-100 rounded"
              onClick={() => handleExampleClick(exampleData3)}
            >
              <strong>A study of Thutankhamuns collar</strong> 
            </div>
            <div 
              className="border border-gray-300 p-4 cursor-pointer hover:bg-gray-100 rounded"
              onClick={() => handleExampleClick(exampleData4)}
            >
              <strong>Attention is all you need!</strong> 
            </div>
          </div>
        </div>

        <div className="flex flex-col space-y-4">
          {/* Header for Source Data */}
          <h2 className="text-lg font-semibold">Source Data</h2>
          <textarea
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            className="w-full p-3 border border-gray-300 rounded-md h-32"
            placeholder="Enter your source data here..."
            disabled={isProcessing}
          />

          {/* Customization Text Box */}
          <h2 className="text-lg font-semibold">Customization</h2>
          <textarea
            value={customizationText}
            onChange={(e) => setCustomizationText(e.target.value)}
            className="w-full p-3 border border-gray-300 rounded-md h-32"
            placeholder="Enter any customization options here..."
            disabled={isProcessing}
          />

          <div className="flex justify-between items-center">
            <Button
              onClick={handleSubmit}
              disabled={isProcessing}
              variant="success"
            >
              {isProcessing ? 'Processing...' : 'Generate Graph'}
            </Button>
            <span className="text-gray-600">{status}</span>
          </div>
        </div>
      </div>

      {graphData && (
        <div className="mt-6 bg-white rounded-lg shadow-lg p-4" style={{ height: '800px' }}>
          <h2 className="text-xl font-semibold mb-4">Generated Graph</h2>
          <div className="h-full">
            <CurveView graphData={graphData} />
          </div>
        </div>
      )}
    </div>
  );
};

export default BuilderPage;
