import React, { useState, useEffect } from 'react';
import Modal from '../components/common/Modal';
import { Link } from 'react-router-dom';
import emailjs from 'emailjs-com';
import { cachedExamples } from '../cached_examples';
import CurveView from '../components/graph/CurveView';
import { FaCog, FaInfoCircle } from 'react-icons/fa';

const Home = () => {
  const [selectedExample, setSelectedExample] = useState(null);
  const [showBetaModal, setShowBetaModal] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);

  const [showSourceModal, setShowSourceModal] = useState(false);
  const [sourceText, setSourceText] = useState('');
  
  const [exampleData1, setExampleData1] = useState('');
  const [exampleData2, setExampleData2] = useState('');
  const [exampleData3, setExampleData3] = useState('');
  const [exampleData4, setExampleData4] = useState('');

  // Fetch the example files on mount
  useEffect(() => {
    fetch('/example1.txt')
      .then(response => response.text())
      .then(data => setExampleData1(data))
      .catch(err => console.error('Error loading example1:', err));

    fetch('/example2.txt')
      .then(response => response.text())
      .then(data => setExampleData2(data))
      .catch(err => console.error('Error loading example2:', err));

    fetch('/example3.txt')
      .then(response => response.text())
      .then(data => setExampleData3(data))
      .catch(err => console.error('Error loading example3:', err));

    fetch('/example4.txt')
      .then(response => response.text())
      .then(data => setExampleData4(data))
      .catch(err => console.error('Error loading example4:', err));
  }, []);

  const handleOpenModal = () => {
    setShowBetaModal(true);
  };

  const handleCloseModal = () => {
    setShowBetaModal(false);
  };

  const handleBetaSignUp = (e) => {
    e.preventDefault();
    const email = e.target.betaEmail.value;
  
    emailjs.send(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
      { 
        to_name: "Rasmus",
        from_name: email,
        message: "User entered message here",
        user_email: email
      },
      process.env.REACT_APP_EMAILJS_PUBLIC_KEY
    )
    .then((response) => {
       console.log('SUCCESS!', response.status, response.text);
       alert('Request received. We will be in touch soon!');
       handleCloseModal();
    }, (err) => {
       console.error('FAILED...', err);
       alert('Failed to send email.');
    });
  };

  const examples = [
    { key: 'example1', label: 'EU AI ACT', data: cachedExamples.example1, text: exampleData1 },
    { key: 'example2', label: 'Introductory Abstract Algebra', data: cachedExamples.example2, text: exampleData2 },
    { key: 'example3', label: "A study of Thutankhamun's collar", data: cachedExamples.example3, text: exampleData3 },
    { key: 'example4', label: 'Attention is all you need!', data: cachedExamples.example4, text: exampleData4 },
  ];

  const handleGenerate = (exampleKey) => {
    if (isGenerating) return;
    setIsGenerating(true);
    setTimeout(() => {
      setSelectedExample(exampleKey);
      setIsGenerating(false);
    }, 2000);
  };

  const handleViewSource = (exampleKey) => {
    const example = examples.find(ex => ex.key === exampleKey);
    if (example && example.text) {
      setSourceText(example.text);
      setShowSourceModal(true);
    }
  };

  const selectedData = selectedExample
    ? examples.find(ex => ex.key === selectedExample)?.data
    : null;

  return (
    <div className="min-h-screen flex flex-col bg-gray-50">
      {/* Hero Section */}
      <header className="bg-white shadow">
        <div className="max-w-7xl mx-auto px-6 py-10 text-center">
          <h1 className="text-4xl font-bold text-gray-800 mb-4">
            Knowledge Graphs for a New Era of AI
          </h1>
          <p className="text-gray-600 leading-relaxed max-w-3xl mx-auto">
            Atlaz empowers you to build, visualize, and deploy knowledge graphs—turning raw data into 
            powerful information structures for accurate, explainable, and safe AI.
          </p>
          <div className="mt-8 space-x-4">
            <Link
              to="/login"
              className="inline-block px-6 py-3 rounded-md text-sm font-medium text-white bg-success hover:bg-success transition"
            >
              Try it on your data! - It's free!
            </Link>
            <Link
              to="/about"
              className="inline-block px-6 py-3 rounded-md text-sm font-medium text-gray-700 bg-gray-200 hover:bg-gray-300 transition"
            >
              Learn More
            </Link>
          </div>
        </div>
      </header>

      {/* Main Content Section */}
      <main className="flex-grow bg-white">
        <div className="max-w-5xl mx-auto px-6 py-16">
          {/* Two-column layout: 50-50 split */}
          <div className="flex">
            {/* Left column for examples (50% width) */}
            <div className="w-1/2 pr-6">
              <h2 className="text-lg font-semibold mb-2">Examples</h2>
              <div className="space-y-4">
                {examples.map((ex) => (
                  <div 
                    key={ex.key}
                    className="border border-gray-300 p-4 rounded flex justify-between items-center space-x-2"
                  >
                    <span className="flex-1">{ex.label}</span>
                    <button
                      onClick={() => handleViewSource(ex.key)}
                      className="text-gray-200 hover:text-gray-300"
                      title="View Source"
                    >
                      <FaInfoCircle size={18} />
                    </button>
                    <button
                      onClick={() => handleGenerate(ex.key)}
                      className="text-[#A9C47C] hover:text-[#8DA25F]"
                       title="Generate"
                    >
                      <FaCog size={20} />
                    </button>
                  </div>
                ))}
              </div>

              {isGenerating && (
                <div className="mt-4 text-center text-gray-500">
                  Generating graph...
                </div>
              )}

              {selectedData && !isGenerating && (
                <div className="mt-6 bg-white border border-gray-300 rounded p-4">
                  <h3 className="text-lg font-bold mb-2 text-gray-800">Generated Graph:</h3>
                  <CurveView graphData={selectedData} height={200} />
                </div>
              )}
            </div>

            {/* Right column for main content */}
            <div className="w-3/4">
              <h2 className="text-3xl font-bold text-gray-800 mb-8 text-center">Why Knowledge Graphs?</h2>
              <div className="text-gray-700 leading-relaxed space-y-6">
              <p>
              Log in to build your own graph, based on your own data - it's free! You can also try an example by clicking on a cog to the left!
              </p>
              <p>
              Knowledge is the key to a flourishing world. When we can extract general concepts from our 
              experiences and make them accessible to others, we reduce unnecessary suffering and turn the 
              remaining challenges into more meaningful endeavors. With the invention of language, it became 
              possible to make knowledge concrete and storable. On top of language sits all human progress. 
              Now, as LLMs (Large Language Models) revolutionize how we access and understand knowledge 
              from the internet, we stand on the brink of a new era of knowledge.
              </p>

              <p>
                LLMs excel at synthesizing information—writing code, answering questions, summarizing text, 
                and more. However, they struggle to truly understand the information they process. This is 
                where knowledge graphs come in. Knowledge graphs represent information in a structured, 
                machine-readable way, enabling both humans and AI to reason about information more effectively.
              </p>

              <p>
                GraphRAG has emerged as a powerful Knowledge Graph-based RAG (Retrieval Augmented Generation) strategy. But Knowledge Graphs 
                aren't just another tool in the RAG toolbox. It's a different modality which will be integral to 
                how LLMs will retrieve, memorize, and reason about information. With programmatic structures, 
                there's no need for AI to hallucinate answers—it can rely on a well-organized, verifiable 
                knowledge foundation.
              </p>

              <p>
                The future of accurate, explainable, and safe AI lies in knowledge graphs. That's why we 
                built Atlaz. Atlaz produces Knowledge Engines that allow you to build, visualize, and deploy 
                knowledge graphs, opening up a new wave of AI application. Historically, building and maintaining 
                Knowledge Graphs required substantial technical expertise. We’re changing that. Atlaz can 
                automatically generate graphs from your domain-specific prompts, and then serve them through 
                a serverless API, ready to power your data extraction and integrate into your RAG pipelines. 
                For the really complex tasks, the end-to-end Atlaz Reasoning Engine is ready to tackle your toughest challenges with both retrieval and reasoning.
              </p>

              <p>
                We are currently inviting beta users to try our knowledge engine, Raspberry. If you are 
                interested in exploring how Raspberry can elevate your application, please sign up for beta access. To try the model, just click log in!
              </p>

                <div className="mt-10 text-center">
                  <button
                    onClick={handleOpenModal}
                    className="inline-block px-6 py-3 rounded-md text-sm font-medium text-white bg-[#A9C47C] hover:bg-[#8DA25F] transition"
                  >
                    Sign up for Beta Access!
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Modal 
  isOpen={showSourceModal}
  onClose={() => setShowSourceModal(false)}
  title="Source Text"
>
  <div className="overflow-auto max-h-96 whitespace-pre-wrap text-gray-700 text-sm">
    {sourceText}
  </div>
</Modal>
      {/* Modal for Beta Sign-Up */}
      <Modal 
        isOpen={showBetaModal}
        onClose={handleCloseModal}
        title="Request Beta Access"
      >
        <form onSubmit={handleBetaSignUp}>
          <label className="block mb-1 font-medium text-gray-700">Email</label>
          <input
            type="email"
            name="betaEmail"
            placeholder="you@example.com"
            className="w-full p-3 border border-gray-300 rounded-md mb-4"
            required
          />
          <button
            type="submit"
            className="w-full p-3 bg-[#A9C47C] text-white font-semibold rounded-md"
          >
            Request Beta Access
          </button>
        </form>
      </Modal>
    </div>
  );
};

export default Home;