// src/pages/About.jsx
import React from 'react';

const About = () => {
  return (
    <div className="mt-20 max-w-3xl mx-auto bg-white p-8 rounded-md shadow-md">
      <h2 className="text-3xl font-bold mb-6 text-gray-800 text-center">About Atlaz</h2>
      <div className="flex flex-col md:flex-row items-center md:items-center space-y-6 md:space-y-0 md:space-x-8">
        {/* Text Section */}
        <div className="text-gray-700 leading-relaxed md:w-2/3">
          <p className="mb-4">
            Atlaz was founded by me, Rasmus, based on a vision of a new era of AI powered by knowledge graphs. Ever since growing up, I have been fascinated with the idea of structuring information. In primary school I would spend hours drawing geographical maps, and as a student I would spend days organizing my course material into meticulous mind maps in Microsoft Excel.
          </p>
          <p>
            After discovering Knowledge Graphs, I was trapped. It became a hobby taking up more and more of time, and grew into an integral way of how I worked and studied. The idea of a perfect data model and the potential it holds for AI was eventually too much to resist. I started Atlaz to empower others to build, visualize, and deploy knowledge graphs—turning raw data into powerful information structures for accurate, explainable, and safe AI.
          </p>
        </div>
        {/* Image Section */}
        <div className="md:w-1/3 flex justify-center items-center">
          <img 
            src="/founder_image.png" 
            alt="Rasmus, Founder of Atlaz" 
            className="w-48 h-auto"
          />
        </div>
      </div>
    </div>
  );
};

export default About;
