// src/cached_examples.js

export const cachedExamples = {

    example2: {
        nodes: [
            { id: "1", label: "Global Concept", color: "lightgreen", shape: "box" },
            { id: "2", label: "Monoid", color: "lightblue", shape: "box" },
            { id: "3", label: "Group", color: "lightblue", shape: "box" },
            { id: "4", label: "Cyclic Group", color: "lightblue", shape: "box" },
            { id: "5", label: "Abelian Group", color: "lightblue", shape: "box" },
            { id: "6", label: "Normal Subgroup", color: "lightblue", shape: "box" },
            { id: "7", label: "Factor Group", color: "lightblue", shape: "box" },
            { id: "8", label: "Permutation Group", color: "lightblue", shape: "box" },
            { id: "9", label: "Symmetric Group", color: "lightblue", shape: "box" },
            { id: "10", label: "Direct Product", color: "lightblue", shape: "box" },

            { id: "11", label: "Mathematical Concept", color: "lightyellow", shape: "box" },
            { id: "12", label: "Law of Composition", color: "lightyellow", shape: "box" },
            { id: "13", label: "Unit Element", color: "lightyellow", shape: "box" },
            { id: "14", label: "Inverse Element", color: "lightyellow", shape: "box" },
            { id: "15", label: "Homomorphism", color: "lightyellow", shape: "box" },
            { id: "16", label: "Isomorphism", color: "lightyellow", shape: "box" },
            { id: "17", label: "Kernel", color: "lightyellow", shape: "box" },
            { id: "18", label: "Image", color: "lightyellow", shape: "box" },
            { id: "19", label: "Order of a Group", color: "lightyellow", shape: "box" },
            { id: "20", label: "Coset", color: "lightyellow", shape: "box" },

            { id: "21", label: "Mathematician", color: "orange", shape: "box" },
            { id: "22", label: "N. H. Abel", color: "orange", shape: "box" },
            { id: "23", label: "E. Galois", color: "orange", shape: "box" },
            { id: "24", label: "S. Lie", color: "orange", shape: "box" },
            { id: "25", label: "H. Weber", color: "orange", shape: "box" },
        ],
        edges: [
            { source: "1", target: "2", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },
            { source: "1", target: "11", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },
            { source: "1", target: "21", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },

            { source: "2", target: "3", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },

            { source: "11", target: "12", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },
            { source: "11", target: "13", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },
            { source: "11", target: "14", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },
            { source: "11", target: "15", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },
            { source: "11", target: "16", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },
            { source: "11", target: "17", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },
            { source: "11", target: "18", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },
            { source: "11", target: "19", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },
            { source: "11", target: "20", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },

            { source: "21", target: "22", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },
            { source: "21", target: "23", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },
            { source: "21", target: "24", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },
            { source: "21", target: "25", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },

            { source: "3", target: "4", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },
            { source: "3", target: "5", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },
            { source: "3", target: "6", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },
            { source: "3", target: "7", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },
            { source: "3", target: "8", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },
            { source: "3", target: "10", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },

            { source: "8", target: "9", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },

            { source: "3", target: "12", label: "Foundation of", color: "black", style: "solid", arrowShape: "diamond", width: 2 },
            { source: "3", target: "19", label: "Depends on", color: "black", style: "solid", arrowShape: "diamond", width: 2 },
            { source: "3", target: "9", label: "Subgroup of", color: "black", style: "solid", arrowShape: "diamond", width: 2 },

            { source: "23", target: "8", label: "Tool for", color: "black", style: "solid", arrowShape: "diamond", width: 2 },
            { source: "6", target: "23", label: "Related to", color: "black", style: "solid", arrowShape: "diamond", width: 2 },
        ],
        clusters: []
    },

    example3: {
        nodes: [
            { id: "1", label: "Concept", color: "lightgreen", shape: "box" },
            { id: "2", label: "Exhibition", color: "lightblue", shape: "box" },
            { id: "3", label: "Tutankhamun's Wardrobe", color: "lightblue", shape: "box" },
            { id: "4", label: "Weaving the World", color: "lightblue", shape: "box" },
            { id: "5", label: "Textile Artifact", color: "lightyellow", shape: "box" },
            { id: "6", label: "Tutankhamun's Collar", color: "lightyellow", shape: "box" },
            { id: "7", label: "Carter 21aa", color: "lightyellow", shape: "box" },
            { id: "8", label: "Carter 21bb", color: "lightyellow", shape: "box" },
            { id: "9", label: "Weaving", color: "orange", shape: "box" },
            { id: "10", label: "Warp-faced Tabby", color: "orange", shape: "box" },
            { id: "11", label: "Warp-faced Tabby with Extra Warp System", color: "orange", shape: "box" },
            { id: "12", label: "Person", color: "pink", shape: "box" },
            { id: "13", label: "Marie Ekstedt Bjersing", color: "pink", shape: "box" },
            { id: "14", label: "Gillian Vogelsang-Eastwood", color: "pink", shape: "box" },
            { id: "15", label: "Kazuyo Nomura", color: "pink", shape: "box" },
            { id: "16", label: "Weaving Tool", color: "lime", shape: "box" },
            { id: "17", label: "Treadle Loom", color: "lime", shape: "box" },
            { id: "18", label: "Backstrap Loom", color: "lime", shape: "box" },
        ],
        edges: [
            { source: "1", target: "2", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },
            { source: "1", target: "5", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },
            { source: "1", target: "9", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },
            { source: "1", target: "12", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },
            { source: "1", target: "16", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },

            { source: "2", target: "3", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },
            { source: "2", target: "4", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },

            { source: "5", target: "6", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },
            { source: "5", target: "7", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },
            { source: "5", target: "8", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },

            { source: "9", target: "10", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },
            { source: "9", target: "11", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },

            { source: "12", target: "13", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },
            { source: "12", target: "14", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },
            { source: "12", target: "15", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },

            { source: "16", target: "17", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },
            { source: "16", target: "18", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },

            { source: "6", target: "3", label: "Exhibited In", color: "black", style: "solid", arrowShape: "diamond", width: 2 },
            { source: "6", target: "4", label: "Exhibited In", color: "black", style: "solid", arrowShape: "diamond", width: 2 },

            { source: "13", target: "6", label: "Reconstructed By", color: "black", style: "solid", arrowShape: "diamond", width: 2 },
            { source: "14", target: "13", label: "Collaborated With", color: "black", style: "solid", arrowShape: "diamond", width: 2 },
            { source: "15", target: "13", label: "Collaborated With", color: "black", style: "solid", arrowShape: "diamond", width: 2 },

            { source: "17", target: "6", label: "Used Tool", color: "black", style: "solid", arrowShape: "diamond", width: 2 },
            { source: "18", target: "6", label: "Inspired By", color: "black", style: "solid", arrowShape: "diamond", width: 2 },
        ],
        clusters: []
    },



    example4: {
        nodes: [
            { id: "1", label: "Concept", color: "lightgreen", shape: "box" },
            { id: "2", label: "Neural Network Model", color: "lightblue", shape: "box" },
            { id: "3", label: "Recurrent Neural Network (RNN)", color: "lightblue", shape: "box" },
            { id: "4", label: "Long Short-Term Memory (LSTM)", color: "lightblue", shape: "box" },
            { id: "5", label: "Gated Recurrent Unit (GRU)", color: "lightblue", shape: "box" },
            { id: "6", label: "Convolutional Neural Network (CNN)", color: "lightblue", shape: "box" },
            { id: "7", label: "Transformer", color: "lightblue", shape: "box" },
            { id: "8", label: "Attention Mechanism", color: "lightyellow", shape: "box" },
            { id: "9", label: "Self-Attention", color: "lightyellow", shape: "box" },
            { id: "10", label: "Scaled Dot-Product Attention", color: "lightyellow", shape: "box" },
            { id: "11", label: "Multi-Head Attention", color: "lightyellow", shape: "box" },
            { id: "12", label: "Machine Translation Task", color: "orange", shape: "box" },
            { id: "13", label: "WMT 2014 English-to-German Translation Task", color: "orange", shape: "box" },
            { id: "14", label: "WMT 2014 English-to-French Translation Task", color: "orange", shape: "box" },
            { id: "15", label: "Training Technique", color: "pink", shape: "box" },
            { id: "16", label: "Byte-Pair Encoding", color: "pink", shape: "box" },
            { id: "17", label: "Adam Optimizer", color: "pink", shape: "box" },
            { id: "18", label: "Dropout", color: "pink", shape: "box" },
            { id: "19", label: "Label Smoothing", color: "pink", shape: "box" },
            { id: "20", label: "Evaluation Metric", color: "lime", shape: "box" },
            { id: "21", label: "BLEU Score", color: "lime", shape: "box" },
        ],
        edges: [
            { source: "1", target: "2", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },
            { source: "1", target: "12", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },
            { source: "1", target: "15", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },
            { source: "1", target: "20", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },
            { source: "1", target: "8", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },

            { source: "2", target: "3", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },
            { source: "2", target: "6", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },
            { source: "2", target: "7", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },

            { source: "12", target: "13", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },
            { source: "12", target: "14", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },

            { source: "15", target: "16", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },
            { source: "15", target: "17", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },
            { source: "15", target: "18", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },
            { source: "15", target: "19", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },

            { source: "20", target: "21", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },

            { source: "8", target: "9", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },
            { source: "8", target: "10", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },
            { source: "8", target: "11", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },

            { source: "3", target: "4", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },
            { source: "3", target: "5", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },

            { source: "7", target: "9", label: "uses", color: "black", style: "solid", arrowShape: "diamond", width: 2 },
            { source: "7", target: "11", label: "uses", color: "black", style: "solid", arrowShape: "diamond", width: 2 },
            { source: "7", target: "13", label: "achieves", color: "black", style: "solid", arrowShape: "diamond", width: 2 },
            { source: "7", target: "14", label: "achieves", color: "black", style: "solid", arrowShape: "diamond", width: 2 },

            { source: "21", target: "13", label: "evaluates", color: "black", style: "solid", arrowShape: "diamond", width: 2 },
            { source: "21", target: "14", label: "evaluates", color: "black", style: "solid", arrowShape: "diamond", width: 2 },
        ],
        clusters: []
    },
    example1: {
        nodes: [
            { id: "1", label: "AI Act", color: "lightgreen", shape: "box" },
            { id: "2", label: "AI Innovation Package", color: "lightgreen", shape: "box" },
            { id: "3", label: "Coordinated Plan on AI", color: "lightgreen", shape: "box" },
            { id: "4", label: "Unacceptable Risk", color: "lightblue", shape: "box" },
            { id: "5", label: "High Risk", color: "lightblue", shape: "box" },
            { id: "6", label: "Limited Risk", color: "lightblue", shape: "box" },
            { id: "7", label: "Minimal or No Risk", color: "lightblue", shape: "box" },
            { id: "8", label: "General-purpose AI models", color: "lightyellow", shape: "box" },
            { id: "9", label: "Remote Biometric Identification Systems", color: "lightyellow", shape: "box" },
            { id: "10", label: "European AI Office", color: "orange", shape: "box" },
            { id: "15", label: "Risk Level", color: "lightblue", shape: "box" },
            { id: "16", label: "AI System", color: "lightyellow", shape: "box" },
            { id: "17", label: "Concept", color: "pink", shape: "box" },
        ],
        edges: [
            { source: "1", target: "2", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },
            { source: "1", target: "3", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },

            { source: "5", target: "9", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },

            { source: "15", target: "4", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },
            { source: "15", target: "5", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },
            { source: "15", target: "6", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },
            { source: "15", target: "7", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },

            { source: "16", target: "8", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },
            { source: "16", target: "9", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },

            { source: "17", target: "1", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },
            { source: "17", target: "15", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },
            { source: "17", target: "16", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },
            { source: "17", target: "10", label: "Type of", color: "black", style: "solid", arrowShape: "normal", width: 2 },

            { source: "1", target: "2", label: "Part of", color: "black", style: "solid", arrowShape: "diamond", width: 2 },
            { source: "1", target: "3", label: "Part of", color: "black", style: "solid", arrowShape: "diamond", width: 2 },

            { source: "10", target: "1", label: "Oversees", color: "black", style: "solid", arrowShape: "diamond", width: 2 },

            { source: "5", target: "9", label: "Includes", color: "black", style: "solid", arrowShape: "diamond", width: 2 },
        ],
        clusters: []
    }
};