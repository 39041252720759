// src/components/graph/CurveView.jsx
import React, { useMemo } from 'react';
import KnowledgeGraph from './KnowledgeGraph';
import { createGraphData } from '../../graphData';

const CurveView = ({ graphData, height = 600 }) => {
  const processedGraphData = useMemo(() => createGraphData(graphData), [graphData]);

  return (
    <div style={{ width: '100%', height: `${height}px` }}>
      <KnowledgeGraph data={processedGraphData} height={height} />
    </div>
  );
};

export default CurveView;